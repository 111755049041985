import React from 'react';

import { Link } from '@mui/material';

import ContactForm from '../components/ContactForm';
import { ContactInfo } from '../components/ContactInfo';
import Content from '../components/Content';
import { Footer } from '../components/Footer';
import Header from '../components/Header';
import Heading from '../components/Heading';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import List from '../components/List';
import Paragraph from '../components/Paragraph';
import SEO from '../components/SEO';

export default function IndexPage() {
  return (
    <Layout>
      <SEO />

      <Header />
      <Hero />

      <Content>
        <Content.Body>
          <Heading className="mb-4">Autokorjaamomme on parhaimmillaan vaihteisto- ja voimansiirto-ongelmissa</Heading>

          <Paragraph className="mb-3">
            Korjaamme ja huollamme mm. seuraavia automaattivaihteistoja. Lisäksi korjaamme ja huollamme myös
            manuaalilaatikot.
          </Paragraph>

          <List>
            <List.Item>S-tronic</List.Item>
            <List.Item>Powershift</List.Item>
            <List.Item>CVT</List.Item>
            <List.Item>Triptronic</List.Item>
            <List.Item>Multitronic</List.Item>
            <List.Item>Muut DSG-vaihteistot</List.Item>
          </List>
        </Content.Body>
      </Content>

      <Content reverse>
        <Content.Body>
          <Heading className="mb-4">Onko taas aika katsastaa? Ei hätää, me autamme!</Heading>
          <Paragraph className="mb-3">Hoidamme myös pienemmät huollot ja korjaukset.</Paragraph>
          <List>
            <List.Item>Määräaikaishuollot</List.Item>
            <List.Item>Vaihteistohuollot</List.Item>
            <List.Item>Katsastustarkastukset</List.Item>
            <List.Item>Vianmääritysdiagnoosit</List.Item>
            <List.Item>Moottorin kunnostukset sekä kytkinkorjaukset</List.Item>
          </List>
        </Content.Body>
      </Content>

      <Content>
        <Content.Image small shadow src="/innovoice.png" href="https://www.innovoice.fi/kuluttajille" />
        <Content.Body>
          <Heading className="mb-4">Maksa joustavasti laskulla ja osamaksulla!</Heading>

          <Paragraph className="mb-3">
            Saat aina vähintään 30 päivää korotonta maksuaikaa ja voit itse päättää sinulle sopivan maksuajan.
          </Paragraph>

          <Link href="https://www.innovoice.fi/kuluttajille" target="_blank">
            Lue lisää täältä &rarr;
          </Link>
        </Content.Body>
      </Content>

      <ContactInfo />
      <ContactForm />

      <Footer />
    </Layout>
  );
}
